@import 'Styles/includes';

/**
*
* NewsletterSubscription
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.NewsletterSubscription {
    $root: &;

    padding: 36px 0;
    position: relative;

    &--Invert {
        color: white;
    }

    &:after {
        content: '';
        position: absolute;
        width: 100vw;
        height: 100%;
        background-color: rgba($gray-10, 0.5);
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;
    }

    &--Red {
        &:after {
            background-color: rgba($red, 0.1);
        }
    }

    &--Purple {
        &:after {
            background-color: rgba($purple, 0.1);
        }
    }

    &--Blue {
        &:after {
            background-color: rgba($blue, 0.1);
        }
    }

    &--Yellow {
        &:after {
            background-color: rgba($yellow, 0.1);
        }
    }

    @include media(md) {
        padding: 82px 42px;
    }

    @include media(lg) {
        padding: 82px 96px;
    }

    &__BackgroundImage {
        position: absolute;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        width: 100vw;
        height: 100%;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;

        &--Darken {
            &:before {
                content: '';
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                position: absolute;
                background-color: rgba($black, 0.45);
            }
        }
    }

    &__Container {
        @include media(md) {
            #{$root}--FiftyFifty & {
                border-radius: 4px;
                box-shadow: 0 0 8px 0 rgba(black, 0.15);
                padding: 106px 60px 92px 60px;
                background: white;
                max-width: maxwidth(article);
                margin: auto;
            }
        }
    }

    &__Icon {
        font-size: 5.4rem;
        text-align: center;
        width: 100%;
        margin-bottom: 48px;

        @include media(md) {
            #{$root}--FiftyFifty & {
                font-size: 9.6rem;
                margin-bottom: 0;
            }
        }
    }

    &__Image {
        width: 80px;
        height: 80px;
        margin: 0 auto 16px auto;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
    }

    &__Columns {
        display: flex;
        flex-direction: column;

        #{$root}--FiftyFifty & {
            @include media(md) {
                width: 100%;
                flex-direction: row;
            }
        }
    }

    &__Column {
        #{$root}--FiftyFifty & {
            @include media(md) {
                width: 100%;
            }

            &--Icon {
                display: none;

                @include media(md) {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }

    &__Title {
        letter-spacing: 0.2px;
        font-size: 2.4rem;
        line-height: 1.33;
        text-align: center;
        width: 100%;
        font-weight: 700;
        margin-bottom: 12px;

        #{$root}--FiftyFifty & {
            @include media(md) {
                text-align: left;
            }
        }
    }

    &__Preamble {
        letter-spacing: 0.2px;
        font-size: 1.8rem;
        line-height: 1.5;
        text-align: center;
        margin-bottom: 48px;
        margin: 0 auto;
        max-width: 720px;

        @include media(md) {
            padding: 0 48px;
        }

        #{$root}--FiftyFifty & {
            @include media(md) {
                padding: 0;
                max-width: initial;
                text-align: left;
            }
        }
    }

    &__Fields {
        display: flex;
        flex-direction: column;
        margin: 32px 0 0 0;

        @include media(sm) {
            align-items: center;
            margin: 16px auto 0 auto;
            width: 320px;
        }

        #{$root}--FiftyFifty & {
            @include media(md) {
                width: 100%;
                margin: 32px auto 0 auto;
                align-items: flex-start;
            }
        }
    }

    &__Field {
        appearance: none;
        box-shadow: none;
        outline: none;
        background: white;
        width: 100%;
        border: 1px solid $gray-40;
        letter-spacing: 0.36px;
        padding: 12px;
        color: #0c0c0c;
        font-size: 1.6rem;
        border-radius: 4px;
        margin-bottom: 16px;

        @include media(md) {
            margin-bottom: 16px;
            margin-right: 0;

            #{$root}--FiftyFifty & {
                @include media(md) {
                    margin-bottom: 24px;
                    margin-right: 0;
                }
            }
        }

        &:focus {
            border: 1px solid $black;
        }

        &::placeholder {
            color: $gray-40;
            font-family: proxima-nova, Arial;
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        &[type=number] {
            -moz-appearance: textfield;
        }

        &--Invalid {
            border: 1px solid $red;
        }
    }

    &__AcceptanceWrapper {
        display: flex;
        align-items: flex-start;
        margin-bottom: 20px;
        margin-top: 15px;
        padding-top: 15px;
        border-top: 1px solid $gray-40;
        
        @include media(md) {
            border-top: 1px solid $gray-15;
        }
    }

    &__AcceptanceCheckBox {
        outline: none;
        appearance: none;
        position: relative;
        min-width: 25px;
        border: none;
        margin-left: 0;

        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            height: 15px;
            width: 15px;
            border: 1px solid #dddddd;
            background: white;
            border-radius: 4px;
        }

        &--Checked {
            &:before {
                background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2213%22%20height%3D%2210%22%20viewBox%3D%220%200%2013%2010%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M1%203.5L5.5%208l6.002-7%22%20stroke-width%3D%222.5%22%20stroke%3D%22%23FFF%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E');
                background-repeat: no-repeat;
                background-position: center center;
                border: 1px solid $red;
                border-radius: 4px;
                background-color: $red;
            }
        }
    }

    &__AcceptanceText {
        font-style: italic;
        max-width: 320px;

        @include media(md) {
            max-width: none;
        }

        p {
            font-size: 1.2rem;
            line-height: 1.2;
        }

        a {
            color: $red;
            font-size: 1.2rem;
            text-decoration: underline;
        }
    }

    &__SubmitWrapper {
        text-align: center;

        @include media(md) {
            text-align: left;
        }
    }

    &__Success {
        font-size: 1.8rem;
        font-weight: 700;
        margin-top: 24px;
        margin-bottom: 16px;
        text-align: center;

        #{$root}--FiftyFifty & {
            text-align: left;
        }
    }

    &__Error {
        font-size: 1.8rem;
        font-weight: 700;
        margin-top: 24px;
        text-align: center;

        #{$root}--FiftyFifty & {
            text-align: left;
        }

        & a {
            color: black;
        }
    }

    &__Waypoint {
        position: absolute;
        top: 280px;

        @include media(md) {
            top: 350px;
        }
    }

    &__Checkboxes {
        width: 100%;
        margin: 8px 0 16px 0;
    }

    &__CheckboxWrapper {
        margin-bottom: 8px;
    }

    &__CheckBoxLabel {
        display: flex;

        &--Mandatory {
            user-select: none;
            opacity: 0.75;
            pointer-events: none;
        }
    }

    &__CheckBoxText {
        line-height: 1.5;
        color: #545454;
        font-size: 1.6rem;
        width: calc(100% - 35px);
        margin-top: 4px;

        #{$root}--Invert & {
            color: white;
        }
    }
    
    &__CheckBox {
        outline: none;
        appearance: none;
        position: relative;
        width: 35px;
        border: none;
        margin-left: 0;

        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            height: 23px;
            width: 23px;
            border: 1px solid #dddddd;
            background: white;
            border-radius: 4px;
        }

        &--Checked {
            &:before {
                background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2213%22%20height%3D%2210%22%20viewBox%3D%220%200%2013%2010%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M1%203.5L5.5%208l6.002-7%22%20stroke-width%3D%222.5%22%20stroke%3D%22%23FFF%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E');
                background-repeat: no-repeat;
                background-position: center center;
                border: 1px solid $red;
                border-radius: 4px;
                background-color: $red;
            }
        }
    }
}
